import { IMyothernestInfo } from "../interfaces/myothernest.interface";




export const INFO_MYOTHERNEST : IMyothernestInfo = {
    social_media : [
        {
            image: "/assets/images/footer/footer-youtube.png",
            link : "#",
            title: "youtube"
        },
        {
            image: "/assets/images/footer/footer-instagram.png",
            link : "#",
            title : "instagram"
        },
        {
            image: "/assets/images/footer/footer-facebook.png",
            link : "#",
            title :"facebook"
        }
    ],
    phone_contact_us: "+353877019667",
    phones : [
        "+353 1 231 4628",
        "+353 89 461 6561"
    ],
    office : {
        address : "Mespil House, Sussex Rd",
        county: "Dublin",
        number : "4",
        country : "Ireland"
    },
    email : ["hello@myothernest.com","info@myothernest.com"]
}